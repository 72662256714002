




































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { AsyncComponent, VueConstructor } from 'vue'

import { OrdersListItemMixin } from '../../../shared/mixins/ordersListItem.mixin'

import OrderStatus from '../OrderStatus/OrderStatus.vue'

import TipIcon from './partials/TipIcon.vue'
import {
  ORDER_LIST_ITEM_COMPONENT_CONFIG_MAP, ORDER_LIST_ITEM_COMPONENT_KEY,
  statusIconsRegistry
} from './OrdersListItem.config'
import { StructureConfigurable } from '../../../../support/mixins'
import { AnyObject } from '@movecloser/front-core'
import { PossibleOrderStatus } from '../../../../contexts/orders/contracts/orders'
import { BaseCartMixin } from '../../../checkout/shared/mixins/base-cart.mixin'
import { Inject } from '../../../../support'
import { IOrderRenewalService, OrderRenewalServiceKey } from '../../services/order-renewal'
import { ToastMixin } from '../../../shared'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<OrdersListItem>({
  name: 'OrdersListItem',
  components: {
    TipIcon,
    OrderStatus
  },
  created () {
    this.config = this.getComponentConfig(ORDER_LIST_ITEM_COMPONENT_KEY, { ...ORDER_LIST_ITEM_COMPONENT_CONFIG_MAP })
  }
})
export class OrdersListItem extends Mixins(BaseCartMixin, OrdersListItemMixin, StructureConfigurable, ToastMixin) {
  @Inject(OrderRenewalServiceKey)
  protected readonly orderRenewalService?: IOrderRenewalService

  @Prop({ type: Boolean, required: true })
  private readonly isOrderAgainButton!: boolean

  public isRenewalPending: boolean = false

  /**
   * Shows tooltip only when current status is 'canceled'
   */
  public get isTooltipApplicable (): boolean {
    if (!this.item || !this.item.status || !this.item.statusDetails) {
      return false
    }
    return (this.item.status === PossibleOrderStatus.Cancelled) &&
      this.item.statusDetails.replace(' ', '').length > 0
  }

  /**
   * Toggle status component with icon
   * @see statusIconsRegistry
   */
  public get statusIcon (): VueConstructor | AsyncComponent {
    return statusIconsRegistry[this.item.status]
  }

  public get detailsButtonVariant (): AnyObject {
    return this.getConfigProperty<AnyObject>('variant')
  }

  public get detailsButtonTheme (): AnyObject {
    return this.getConfigProperty<AnyObject>('theme')
  }

  public get orderAgainButtonVariant (): AnyObject {
    return this.getConfigProperty<AnyObject>('orderAgainButtonVariant')
  }

  public get orderAgainButtonTheme (): AnyObject {
    return this.getConfigProperty<AnyObject>('orderAgainButtonTheme')
  }

  public get showOrderAgainSuccessInfo (): boolean {
    return this.getConfigProperty<boolean>('showOrderAgainSuccessInfo')
  }

  public get showOrderStatus (): AnyObject {
    return this.getConfigProperty<AnyObject>('showOrderStatus')
  }

  public get renewOrder () {
    return {
      createdAt: this.item.createdAt,
      id: this.item.id,
      products: this.item.products,
      productsCost: this.item.productsCost,
      shippingCost: this.item.shippingCost,
      status: this.item.status,
      totalCost: this.item.totalCost,
      updatedAt: this.item.updatedAt ?? this.item.createdAt
    }
  }

  public async orderAgain () {
    if (!this.orderRenewalService || !this.renewOrder) {
      return false
    }

    try {
      const renewedCart = await this.orderRenewalService.renewCustomerOrder(this.renewOrder)
      if (!renewedCart) {
        return
      }
      this.refreshCart(renewedCart)

      if (this.showOrderAgainSuccessInfo) {
        this.showToast(this.$t('front.orders.views.OrderView.orderAgainSuccess').toString(), 'success')
      }

      this.$emit('reload')
    } catch (e) {
      this.showToast((e as Error).message, 'warning')
    } finally {
      this.isRenewalPending = false
    }
  }
}

export default OrdersListItem
