


















































import { Component, Inject as VueInject, Mixins, Prop } from 'vue-property-decorator'

import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY, logger } from '../../../../support'
import {
  ProductOrderData, RetryPaymentUrl
} from '../../../../contexts/orders/contracts/orders'

import { mapMonthName } from '../../../shared/support/months-mapper'
import { OrdersListItemMixin } from '../../../shared/mixins/ordersListItem.mixin'
import { OrderStatusesMixin } from '../../../shared/mixins/orderStatuses.mixin'
import { ProductCartMixin } from '../../../checkout/shared/mixins/product-cart.mixin'
import { ToastMixin } from '../../../shared'

import TipIcon from './partials/TipIcon.vue'
import { IOrdersRepository, OrdersRepositoryType } from '../../contracts/repository'

/**
 * Component rendering version of OrdersListItem that has a "Retry payment" functionality
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl> (original)
 */
@Component<OrdersListItemPayment>({
  name: 'OrdersListItemPayment',
  components: { TipIcon }
})
export class OrdersListItemPayment extends Mixins(
  OrdersListItemMixin,
  ProductCartMixin,
  OrderStatusesMixin,
  ToastMixin
) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(OrdersRepositoryType)
  protected readonly ordersRepository!: IOrdersRepository

  @Prop({ type: Number, required: true })
  protected readonly maxRetryDays!: number

  @Prop({ type: Boolean, required: false })
  protected readonly showPaymentAmount!: boolean

  public isLoading: boolean = false

  /**
   * Calculates time when button should be visible...
   *
   * Takes into account order's createdAt time in milliseconds as well as maximum available time
   * when user can retry payment (one day multiplied by number of days).
   */
  public get shouldDisplayRetryBtn (): boolean {
    const currentDate = new Date()
    const currentTime = currentDate.getTime()

    const MAX_LASTING_TIME = 1000 * 60 * 60 * 24 * this.maxRetryDays

    const createdAtTime = new Date(this.item.createdAt).getTime()

    return (createdAtTime + MAX_LASTING_TIME) > currentTime
  }

  /**
   * Get status that should enable retry payment action
   */
  public get statusesToPaymentRetry (): Array<string> {
    return Object.keys(this.retryPaymentOrderStatuses)
  }

  /**
   * Date formatted with Locale months names
   * @param date
   */
  public fullFormatDate (date: string) {
    const dateStr = new Date(date.replaceAll('-', '/').replaceAll('.', '/'))
    return `${dateStr.getDate()} ${mapMonthName(
      dateStr.getMonth(),
      this.$i18n
    )} ${dateStr.getFullYear()}`
  }

  /**
   * Used to mark status DOM Element with proper class
   * @param status
   */
  public highlightStatus (status: string) {
    return Object.keys(this.highlightedOrderStatuses).includes(status)
  }

  public async handleRetryPayment (): Promise<void> {
    this.isLoading = true
    if (!this.item.id) {
      return
    }

    try {
      const paymentUrl: RetryPaymentUrl = await this.ordersRepository.generateRetryPaymentUrl(this.item.id.toString())

      if (window && paymentUrl && paymentUrl.url) {
        window.location.href = paymentUrl.url
      }
    } catch (e) {
      logger((e as Error), 'warn')
      this.showToast((e as Error).message, 'danger')
    } finally {
      this.isLoading = false
    }
  }
}

export default OrdersListItemPayment
